import React from "react"
import PropTypes from "prop-types"

import loadable from "@loadable/component"

const FormLink = loadable(() => import("../../components/FormLink"))

const Card = ({ title, bookingID, highlights, price }) => {
  const openBookingSite = evt => {
    evt.preventDefault()
    Booking.Open({ OfferID: bookingID })
  }

  return (
    <div className="p-8 bg-white rounded-8 shadow-card">
      <div className="relative text-gray-800">
        {title && <h4 className="mb-4 font-bold text-black">{title}</h4>}
        {highlights && (
          <ul className="mb-4 checkListAlt">
            {highlights.map((item, index) => (
              <li key={`highlight_${index}`}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        )}
        {price && (
          <p className="my-4">
            {price} <span className="text-xs">(incl VAT)</span>
          </p>
        )}
        {bookingID && (
          <div className="my-2">
            <a
              href="#book"
              className="relative inline-flex text-sm font-bold leading-none text-white button button-full rounded-4 bg-aqua-500 hover:bg-opacity-80"
              onClick={openBookingSite}
            >
              <span>Book now</span>
            </a>
          </div>
        )}
        <div className="my-2">
          <FormLink
            type="button"
            linkLabel="Ask us / the host a question"
            form="Coaching Enquiry"
            page={title}
            full
          />
        </div>
        <p className="mt-4 text-sm text-center">
          Answer within 24 hours. No obligations
        </p>
      </div>
    </div>
  )
}

export default Card

Card.propTypes = {
  title: PropTypes.string,
  bookingID: PropTypes.number,
  highlights: PropTypes.array,
  price: PropTypes.string,
}
