import React from 'react'
import PropTypes from 'prop-types'

import loadable from '@loadable/component'

// import FormLink from '../../components/FormLink'

// const Dates = loadable(() => import('./Dates'))
const Date = loadable(() => import('./Date'))

const Card = ({ highlights, dates, page }) => {
  


  return (
  <>
    {/* {dates?.length > 1 && (
      <div className="p-8 bg-white rounded-8 shadow-card">
        <Dates highlights={highlights} dates={dates} />
      </div>
    )} */}
    {dates?.length === 1 && (
      <div className="p-6 bg-white rounded-8 shadow-card">
        <Date highlights={highlights} dates={dates} page={page} />
      </div>
      )}
  </>
)}

export default Card

Card.propTypes = {
  highlights: PropTypes.array,
  dates: PropTypes.array,
  page: PropTypes.string,
}
